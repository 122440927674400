.Login {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-image: url('../img/home_img.png');
    background-size: cover;
    background-position: -15vh 0px;
    display: flex;
    justify-content: flex-end;
}

.Form {
    display: grid;
    width: 46%;
    height: 100%;
    background: white;
    box-shadow: -5px 0px 12px white;
    font-family: 'Dosis', sans-serif;
}

.Logo {
    padding: 30px;
    display: flex;
    align-items: center;
    width: 100vh;
    height: 100px;
}

.Logo p {
    margin-top: 20px;
    padding-left: 20px;
    color: #348AC7;
    font-size: 1.5rem;
}

.Form span {
    display: flex;
    margin-top: 15px;
    width: 100%;
    padding: 10px;
}

.Form input {
    width: 30vw;
    padding: 12px;
    padding-right: 40px;
    border: 0;
    border-bottom: 4px solid rgba(113, 113, 113, 0.7);
    transition: 0.5s;
}

.Form input:focus {
    color: #348AC7;
    border-bottom: 4px solid #348AC7;
    outline: none;
}

.Form input:focus+.icon {
    color: #348AC7;
}

.icon {
    padding: 10px;
    margin-left: -40px;
    color: rgba(113, 113, 113, 0.7);
    min-width: 50px;
}

.Form button {
    background: linear-gradient(90deg, #7474BF, #348AC7);
    color: white;
    width: 20vw;
    height: 60px;
    border: 0;
    border-radius: 40px;
    position: relative;
    left: 10%;
    right: 10%;
    margin-top: 100px;
    box-shadow: 0px 3px 12px rgb(31, 30, 30);
    cursor: pointer;
    transition: 1s;
}

.Form button span {
    display: block;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.Form button:enabled:hover {
    background: linear-gradient(90deg, #7474BF, #348AC7);
    transform: scale(1.2, 1.2);
}

.Form button:focus {
    outline: none;
}

.Container-form {
    color: #707070;
    position: relative;
    left: 10%;
    right: 10%;
}

:root {
    --size-divisor: 10px;
}

@media screen and (max-width: 768px) {
    .Form {
        width: 100%;
        overflow-x: hidden;
    }
    .Form input {
        width: 60vw;
    }
    .Form button {
        width: 50vw;
        position: relative;
        left: 5%;
        right: 5%;
    }
    .Form button:hover {
        transform: translateX(0%);
    }
    .Container-form {
        position: relative;
        left: 5%;
        right: 5%;
    }
}