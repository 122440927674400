.History {
    padding-top: 70px;
    margin-left: 60px;
    width: 100%;
    height: 100vh;
    color: black;
    background-color: #e4e2dd;
    overflow-x: hidden;
}

.History p {
    text-align: center;
}

@media screen and (max-width: 959px) {
    .History {
        margin-left: 0;    
    }
}