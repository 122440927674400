.Search {
    display: flex;
    justify-content: right;
    margin-right: 90px;
}

.Search input {
    padding: 0 20px 0 10px;
    width: 100%;
    height: 41px;
    border: 0;
    color: white;
    background: linear-gradient( 90deg, #348AC7, #7474BF);
    border-radius: 20px;
    box-shadow: 0 0 15px rgb(128, 127, 127);
}

.Search input::placeholder {
    color: white;
}

.Search input:focus {
    outline: none;
}
