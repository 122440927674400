.table-view {
    padding-top: 20px;
    padding-left: 10px;
    width: 95%;
}

.objective-conclusion {
    width: 90%;
    height: 20vw; 
    text-align: justify;
    border: 1px solid #809FE6;
    border-radius: 10px; 
    padding: 10px;
    color: #809FE6;
    white-space: pre-wrap;
}

.section-title {
    text-align: center;
    color: #809FE6;
}

.section-title u {
    text-decoration-thickness: 4px;
}

.section-data-title {
    text-align: left;
    color: #809FE6;
}

.section-data-title u {
    text-decoration-thickness: 2px;
}

.load-section-btn {
   margin-right: 40px;
   float: right;
}

.calendar table {
    border-radius: 0px;
}

.filter table {
    border-radius: 0px;
    box-shadow: 0px 0px;
}


@media screen and (max-width: 959px) {
    .objective-conclusion {
        width: 90%;
        height: 40%; 
    }
}

@media screen and (max-width: 768px) {
    .objective-conclusion {
        width: 80%;
        height: 40%; 
    }
}
