.Administration {
    padding-top: 70px;
    margin-left:60px !important;
    width: 100%;
    height: 100vh;
    color: black;
    background-color: hsl(0, 8%, 93%) !important;
    /*overflow-x: hidden;*/
}

.Administration p {
    text-align: center;
}


@media screen and (max-width: 960px){
    .Administration {
        padding-top:0px !important;
        margin-left:0px !important;
        width: 100%;
        height: 100vh;
        color: black;
        /*overflow-x: hidden;*/
    }
}

@media screen and (max-width:1100px) {
    h2.fc-toolbar-title {
        font-size:20px !important;
    }
}

@media (max-width: 720px){
    a.fc-col-header-cell-cushion{
        font-size:10px !important;
    }
}

.add_user_dlg{
    overflow-y: overlay !important;
}

