
body, html {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}