.Analitics {
    padding-top: 70px;
    margin-left:60px;
    width: 100%;
    height: 100vh;
    color: black;
    background-color: hsl(0, 8%, 93%);
    overflow-x: hidden;
    overflow-y: scroll;
}


.team-operations {
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


@media screen and (max-width: 959px) {
    .Analitics {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .team-operations {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
}
