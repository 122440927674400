.Schematics {
    width: 100%;
    height: 100vh;
    padding-top: 60px;
    margin-left: 60px;
    background-color: hsl(0, 8%, 93%);
    overflow-y: scroll;
}




@media screen and (max-width: 959px) {
    .Schematics {
        margin-left: 0;
    }

 
}