#input[type="file"] {
  display: none;
}
.label {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.image-upload {
  margin: auto;
  width: 200px;
  height: 50px;
  color: white;
  border-radius: 10px;
  background-color: black;
  text-align: center;
  cursor: pointer;
}
