body {
    overflow-y: scroll !important;
}


::-webkit-scrollbar {
    width: 10px !important;
    height: 0px !important
}

@media screen and (max-width: 960px) {
    ::-webkit-scrollbar {
        width: 0px !important;
        height: 0px !important;
    }
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(24, 112, 224);
    background: linear-gradient(180deg, rgba(24, 112, 224, 1) 0%, rgba(2, 43, 161, 1) 100%);;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(24, 112, 224);
    background: linear-gradient(180deg, rgba(24, 112, 224, 1) 0%, rgba(90, 130, 247, 1) 100%);
}

