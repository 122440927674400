.Profile {
    padding-top: 90px;
    margin-left:60px;
    height: 100vh;
    color: black;
    background-color: hsl(0, 8%, 93%);
    font-family: 'Dosis', sans-serif;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 10px;
}

.p-mr-2-shadow {
    box-shadow: 0 0 20px rgb(146, 145, 145);
    margin: 20px 0 20px 0;
}


.Profile div input {
    box-shadow: 0 0 20px rgb(160, 160, 160);
}

#btn-save {
    margin-bottom: 10px;
}

@media screen and (max-width: 959px) {
    .Profile {
        padding-top: 70px;
        margin-left: 0;
    }
}
